<template>
  <div class="container">
    <h3>{{ $t("market.transactionRecords") }}</h3>
    <div class="tabBox">
        <span :class="{'active': tabActive === 1}" @click="changeActive(1)">{{ $t('market.currentCommission') }}</span>
        <span :class="{'active': tabActive === 2}" @click="changeActive(2)">{{ $t('market.historyCommission') }}</span>
    </div>
    <el-table :data="tableData" style="width: 100%" :empty-text="$t('rankText.emptyText')">
      <el-table-column prop="num" :label="$t('market.tradingPairs')">
      </el-table-column>
      <el-table-column prop="num" :label="$t('market.aTurnover')">
      </el-table-column>
      <el-table-column prop="num" :label="$t('market.newPrice')">
      </el-table-column>
      <el-table-column prop="num" :label="$t('market.orderNum')">
      </el-table-column>
      <el-table-column prop="num" :label="$t('market.status')">
      </el-table-column>
      <el-table-column prop="num" :label="$t('market.entrustmentTime')">
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref } from "vue";
const tableData = ref([
    {
        num: 123
    }
])
const tabActive = ref(1);
const changeActive = (e) => {
    tabActive.value = e;
}
</script>

<style lang="scss" scoped>
.container {
  width: 1140px;
  margin: 35px auto;
  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  }
  .tabBox {
    display: flex;
    justify-content: space-around;
    color: #5670f6;
    font-size: 14px;
    margin: 20px 0;
    span {
        cursor: pointer;
    }
    .active {
        padding-bottom: 5px;
        border-bottom: 2px solid #5670f6;
    }
  }
}
</style>
